<template>
  <div>
    <ScrollTop v-show="showScrollTop" @scrollTop="scrollTop" />
    <div class="card card-custom gutter-b">
      <div class="card-header" v-b-toggle.collapse-filtros>
        <div class="card-title mb-0">
          <h5 class="card-label mb-0">Filtros</h5>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-light-primary mr-1">
            <i
              class="ki icon-nm"
              :class="{
                'ki-arrow-up': cardFiltros,
                'ki-arrow-down': !cardFiltros
              }"
            ></i>
          </a>

          <a
            class="btn btn-sm btn-light-warning mr-1 font-weight-bold"
            @click.prevent="filtrarRelatorio"
          >
            Filtrar
          </a>
        </div>
      </div>

      <b-collapse id="collapse-filtros" v-model="cardFiltros">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <b-form-group>
                <label>Conta</label>
                <pv-multiselect
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltContas"
                  placeholder="Todos"
                  v-model="defaultsSelect.conta"
                  @input="obterContas"
                >
                </pv-multiselect>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Período</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltPeriodos"
                  v-model="defaultsSelect.periodo"
                  @input="obterPeriodo"
                ></pv-dropdown>
              </b-form-group>
            </div>
            <div class="col-md-3" v-show="exibirDatasPeriodo">
              <b-form-group>
                <label>Data inicial</label>
                <b-form-datepicker
                  v-model="frmFiltrarRelatorio.dataInicial"
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-md-3" v-show="exibirDatasPeriodo">
              <b-form-group>
                <label>Data final</label>
                <b-form-datepicker
                  v-model="frmFiltrarRelatorio.dataFinal"
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Canal</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltCanais"
                  :showClear="true"
                  placeholder="Todos"
                  v-model="defaultsSelect.canal"
                  @input="obterCanal"
                ></pv-dropdown>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Status</label>
                <pv-multiselect
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltStatus"
                  placeholder="Todos"
                  v-model="defaultsSelect.status"
                  @input="obterStatus"
                >
                </pv-multiselect>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Modelo</label>
                <pv-multiselect
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltCampanhas"
                  placeholder="Todos"
                  v-model="defaultsSelect.campanha"
                  @input="obterCampanha"
                >
                </pv-multiselect>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Campanha</label>
                <pv-multiselect
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltModelos"
                  placeholder="Todos"
                  v-model="defaultsSelect.modelo"
                  @input="obterModelo"
                >
                </pv-multiselect>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Destinatário/Remetente</label>
                <b-form-input
                  v-model="frmFiltrarRelatorio.numero"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group>
                <label>Conteúdo</label>
                <b-form-input
                  v-model="frmFiltrarRelatorio.conteudo"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="card card-custom">
      <div class="card-header">
        <div class="d-flex align-items-center">
          <div class="card-title">
            <h5 class="card-label">Resultados</h5>
          </div>
        </div>
        <div class="card-toolbar">
          <b-button
            variant="secondary"
            @click="selecionarTodasAsMensagens"
            class="mr-2"
            v-if="modo_selecionar_mensagens"
            >Selecionar Todas as Páginas</b-button
          >
          <b-button
            variant="secondary"
            @click="selecionarUltimasMensagens"
            class="mr-2"
            v-if="modo_selecionar_mensagens"
            >Selecionar Últimos</b-button
          >
          <b-button
            variant="success"
            @click="reenviarMensagensSelecionadas"
            class="mr-2"
            v-if="modo_selecionar_mensagens"
            >Reenviar Mensagens</b-button
          >
          <b-button
            variant="danger"
            @click="cancelarMensagensSelecionadas"
            class="mr-2"
            v-if="modo_selecionar_mensagens"
            >Cancelar Envio de Mensagens</b-button
          >
          <a
            class="btn btn-icon btn-light-success mr-2"
            @click.prevent="exportarArquivo('excel')"
            v-b-tooltip.noninteractive
            title="Baixar Excel"
          >
            <i class="fa-regular fa-file-excel icon-xl"></i>
          </a>
          <a
            class="btn btn-icon btn-light-danger mr-2"
            @click.prevent="exportarArquivo('pdf')"
            v-b-tooltip.noninteractive
            title="Baixar PDF"
          >
            <i class="fa-regular fa-file-pdf icon-xl"></i>
          </a>
          <b-button variant="warning" @click="modoSelecionarMensagens"
            >Cancelar / Reenviar</b-button
          >
        </div>
      </div>
      <div class="card-body">
        <div
          class="alert alert-secondary mb-4 bg-light"
          role="alert"
          v-if="totalRegistros > 0"
        >
          <div class="row">
            <div class="col-md-6">
              Total de mensagens enviadas {{ quantidadeMensagens }}.
            </div>
            <div class="col-md-6 text-right">
              Exibindo de {{ (paginaAtual - 1) * registrosPorPagina + 1 }} a
              {{ Math.min(paginaAtual * registrosPorPagina, totalRegistros) }}
              de um total de {{ totalRegistros }} registros.
            </div>
          </div>
        </div>

        <b-table
          hover
          small
          show-empty
          :fields="cabecalho"
          :items="linhas"
          :current-page="paginaAtual"
          :per-page="registrosPorPagina"
        >
          <template #empty>
            <p class="text-center mb-0">Nenhum registro encontrado.</p>
          </template>
          <template #cell(mensagem_selecionada)="row">
            <b-form-checkbox
              v-model="row.item.mensagem_selecionada"
              v-if="modo_selecionar_mensagens"
              size="lg"
              :disabled="row.item.mensagem_selecionada_desabilitada"
            ></b-form-checkbox>
          </template>
          <template #cell(canal)="row">
            <i class="icon-xl" :class="iconeCanal(row.item.canal)"></i>
          </template>

          <template #cell(campanha)="row">
            <span
              v-b-tooltip.bottom.html="row.item.campanha_nome"
              :disabled="!row.item.campanha_nome"
              >{{ row.item.campanha }}</span
            >
          </template>

          <template #cell(descricaostatus)="row">
            <span
              class="label label-pill label-inline font-weight-bold"
              :class="corStatus(row.item.status)"
              v-b-tooltip.noninteractive
              :title="
                row.item.log_erro ? row.item.log_erro : row.item.descricaostatus
              "
              >{{ row.item.descricaostatus }}</span
            >
          </template>

          <template #cell(actions)="row">
            <div v-if="row.item.canal != 'Email'">
              <modal-historico :msg="row.item" :conta="conta"></modal-historico>
            </div>

            <div v-if="row.item.canal == 'Email'">
              <modal-email :email="row.item.conteudo"></modal-email>
            </div>
          </template>
        </b-table>

        <div class="row" v-if="totalRegistros > 0">
          <div class="col-md-4">
            <b-form-group>
              <b-form-select
                :options="sltRegistrosPorPagina"
                v-model="registrosPorPagina"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-8">
            <b-pagination
              :total-rows="totalRegistros"
              v-model="paginaAtual"
              :per-page="registrosPorPagina"
              align="fill"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import download from "downloadjs";
import Swal from "sweetalert2";

import ModalHistorico from "./ModalHistorico";
import ModalEmail from "./ModalEmail";
import ScrollTop from "./components/ScrollTop.vue";

export default {
  name: "relatorio-detalhado",
  data() {
    return {
      modo_selecionar_mensagens: false,
      conta: {},
      defaultsSelect: {
        periodo: {
          value: "HJ",
          text: "Hoje"
        },
        conta: [],
        canal: null,
        status: null,
        campanha: null,
        modelo: null
      },
      frmFiltrarRelatorio: {
        conta: null,
        periodo: null,
        dataInicial: "",
        dataFinal: "",
        numero: "",
        conteudo: "",
        status: null,
        canal: null,
        campanha: null,
        modelo: null
      },
      cabecalho: [
        {
          key: "carregando",
          label: "Carregando...",
          sortable: false,
          tdClass: "align-middle"
        }
      ],
      linhas: [],
      sltContas: [],
      sltStatus: [],
      sltCanais: [],
      sltPeriodos: [],
      sltCampanhas: [],
      sltModelos: [],
      sltRegistrosPorPagina: [
        {
          value: 20,
          text: "Exibir 20 registros por página"
        },
        {
          value: 50,
          text: "Exibir 50 registros por página"
        },
        {
          value: 100,
          text: "Exibir 100 registros por página"
        }
      ],
      paginaAtual: 1,
      registrosPorPagina: 20,
      totalRegistros: 0,
      quantidadeMensagens: 0,
      cardFiltros: false,
      showScrollTop: false
    };
  },

  components: {
    "modal-historico": ModalHistorico,
    "modal-email": ModalEmail,
    ScrollTop
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatório Detalhado" }]);
    this.inicializar();
  },

  methods: {
    validaMensagemJaEnviada(msg) {
      if (msg.status === "ENV") {
        Swal.fire({
          title: "Erro!",
          text: "Não é possivel reenviar mensagens enviadas.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return true;
      }
      return false;
    },
    validaMensagensDiferentesDePendentes(msg) {
      if (msg.status !== "PEN") {
        Swal.fire({
          title: "Erro!",
          text: "Só é possível cancelar mensagens pendentes.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return true;
      }
      return false;
    },
    validaMensagemPendente(msg) {
      if (msg.status === "PEN") {
        Swal.fire({
          title: "Erro!",
          text: "Não é possivel reenviar mensagens pendentes.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return true;
      }
      return false;
    },
    validaErroEnvioCanalDiferenteWhatsapp(msg) {
      const canal_tipo = msg.canal[0].toUpperCase();
      if (canal_tipo !== "W") {
        Swal.fire({
          title: "Erro!",
          text: "Só é possível reenviar mensagens do Whatsapp.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return true;
      }
      return false;
    },
    erroNenhumaMensagemSelecionada() {
      Swal.fire({
        title: "Erro!",
        text: "Nenhuma mensagem selecionada.",
        icon: "error",
        confirmButtonText: "Ok"
      });
      return false;
    },
    validaErroMaisDeDoisDiasAtras(msg) {
      let data = new Date(msg.data);
      let dataAtual = new Date();
      let diferenca = dataAtual.getTime() - data.getTime();
      let dias = Math.ceil(diferenca / (1000 * 3600 * 24));
      if (dias > 2) {
        Swal.fire({
          title: "Erro!",
          text: "Não é possível reenviar mensagens com mais de 2 dias.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return true;
      }
      return false;
    },
    selecionarUltimasMensagens() {
      for (
        let i = 0;
        i < this.registrosPorPagina && i < this.linhas.length;
        i++
      ) {
        const msg = this.linhas[i];
        if (msg.mensagem_selecionada_desabilitada) continue;
        msg.mensagem_selecionada = !msg.mensagem_selecionada;
      }
    },
    selecionarTodasAsMensagens() {
      for (let i = 0; i < this.linhas.length; i++) {
        const msg = this.linhas[i];
        if (msg.mensagem_selecionada_desabilitada) continue;
        msg.mensagem_selecionada = !msg.mensagem_selecionada;
      }
    },
    cancelarMensagensSelecionadas() {
      let nenhuma_mensagem_selecionada = true;
      const mensagens_para_cancelar = [];
      for (let i = 0; i < this.linhas.length; i++) {
        const msg = this.linhas[i];
        if (msg.mensagem_selecionada) {
          nenhuma_mensagem_selecionada = false;
          if (this.validaErroEnvioCanalDiferenteWhatsapp(msg)) return false;
          if (this.validaMensagensDiferentesDePendentes(msg, true))
            return false;
          mensagens_para_cancelar.push(msg.mensagem_id);
        }
      }
      if (nenhuma_mensagem_selecionada) {
        this.erroNenhumaMensagemSelecionada();
        return false;
      }
      ApiService.post("relatorio/cancelar-mensagens", {
        mensagens: mensagens_para_cancelar
      })
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: "Mensagens canceladas com sucesso.",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.filtrarRelatorio();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Erro!",
            text: "Erro ao cancelar mensagens.",
            icon: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    reenviarMensagensSelecionadas() {
      let nenhuma_mensagem_selecionada = true;
      const mensagens_para_reenviar = [];
      for (let i = 0; i < this.linhas.length; i++) {
        const msg = this.linhas[i];
        if (msg.mensagem_selecionada) {
          nenhuma_mensagem_selecionada = false;
          // if (this.validaErroMaisDeDoisDiasAtras(msg)) return false;
          // if (this.validaErroEnvioCanalDiferenteWhatsapp(msg)) return false;
          if (this.validaMensagemJaEnviada(msg)) return false;
          if (this.validaMensagemPendente(msg)) return false;
          mensagens_para_reenviar.push(msg.mensagem_id);
        }
      }
      if (nenhuma_mensagem_selecionada) {
        this.erroNenhumaMensagemSelecionada();
        return false;
      }
      ApiService.post("relatorio/reenviar-mensagens", {
        mensagens: mensagens_para_reenviar
      })
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: "Mensagens reenviadas com sucesso.",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.filtrarRelatorio();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Erro!",
            text: "Não foi possivel reenviar todas as mensagens.",
            icon: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    modoSelecionarMensagens() {
      this.modo_selecionar_mensagens = !this.modo_selecionar_mensagens;
    },
    inicializar() {
      const periodo = this.$route.params.periodo;
      const modelo = this.$route.params.modelo;

      if (periodo) {
        this.frmFiltrarRelatorio.periodo = periodo.periodo.value;
        this.defaultsSelect.periodo = periodo.periodo;
        this.frmFiltrarRelatorio.dataInicial =
          periodo.dataInicial.format("YYYY-MM-DD");
        this.frmFiltrarRelatorio.dataFinal =
          periodo.dataFinal.format("YYYY-MM-DD");
      }

      if (modelo) {
        this.defaultsSelect.modelo = modelo;
        this.frmFiltrarRelatorio.modelo = [modelo[0].value];
      }

      ApiService.post("relatorio/detalhado", this.frmFiltrarRelatorio).then(
        (response) => {
          this.conta = response.data.data.conta;
          this.cabecalho = [
            {
              key: "mensagem_selecionada",
              label: "",
              sortable: false,
              tdClass: "align-middle"
            },
            ...response.data.data.cabecalho
          ];
          this.linhas = response.data.data.resultado.map((v) => {
            v.mensagem_selecionada = false;
            if (v.satus == "ENV") {
              v.mensagem_selecionada_desabilitada = true;
            }
            // const canal_tipo = v.canal[0].toUpperCase();
            // if (canal_tipo !== "W") {
            //   v.mensagem_selecionada_desabilitada = true;
            // }
            return v;
          });
          this.paginaAtual = 1;
          this.registrosPorPagina = 20;
          this.totalRegistros = this.linhas.length;
          this.sltContas = response.data.data.sltContas;
          this.sltStatus = response.data.data.sltStatus;
          this.sltCanais = response.data.data.sltCanais;
          this.sltPeriodos = response.data.data.sltPeriodos;
          this.sltCampanhas = response.data.data.sltCampanhas;
          this.sltModelos = response.data.data.sltModelos;
          this.quantidadeMensagens = response.data.data.quantidadeMensagens;
        }
      );
    },

    filtrarRelatorio(e = null) {
      if (e) e.stopPropagation();

      ApiService.post(
        "relatorio/detalhado/filtrar",
        this.frmFiltrarRelatorio
      ).then((response) => {
        this.cabecalho = [
          {
            key: "mensagem_selecionada",
            label: "",
            sortable: false,
            tdClass: "align-middle"
          },
          ...response.data.data.cabecalho
        ];
        this.linhas = response.data.data.resultado.map((v) => {
          v.mensagem_selecionada = false;
          const canal_tipo = v.canal[0].toUpperCase();
          // if (canal_tipo !== "W") {
          //   v.mensagem_selecionada_desabilitada = true;
          // }
          return v;
        });
        this.paginaAtual = 1;
        this.totalRegistros = this.linhas.length;
        this.quantidadeMensagens = response.data.data.quantidadeMensagens;

        this.cardFiltros = false;
      });
    },

    corStatus(status) {
      if (status == "ENV") {
        return "label-light-success";
      } else if (status == "REC") {
        return "label-light-primary";
      } else if (status == "PEN") {
        return "label-light-warning";
      }

      return "label-light-danger";
    },

    corCanal(canal) {
      if (canal == "Whatsapp") {
        return "label-success";
      } else if (canal == "SMS") {
        return "label-primary";
      } else if (canal == "Email") {
        return "label-primary";
      }

      return "label-warning";
    },

    iconeCanal(canal) {
      if (canal == "Whatsapp") {
        return "fab fa-whatsapp text-success";
      } else if (canal == "SMS") {
        return "fas fa-sms text-primary";
      } else if (canal == "Email") {
        return "fas fa-envelope text-primary";
      }

      return "far fa-comment text-info";
    },

    obterContas(arr) {
      this.frmFiltrarRelatorio.conta = arr.map((o) => o.value);
    },

    obterCanal(obj) {
      this.frmFiltrarRelatorio.canal = obj ? obj.value : null;
    },

    obterStatus(arr) {
      this.frmFiltrarRelatorio.status = arr.map((o) => o.value);
    },

    obterPeriodo(obj) {
      this.frmFiltrarRelatorio.periodo = obj ? obj.value : null;
    },

    obterCampanha(arr) {
      this.frmFiltrarRelatorio.campanha = arr.map((o) => o.value);
    },

    obterModelo(arr) {
      this.frmFiltrarRelatorio.modelo = arr.map((o) => o.value);
    },

    exportarArquivo(tipo) {
      ApiService.post(
        `relatorio/detalhado/exportar/${tipo}`,
        { mensagens: this.linhas },
        {
          responseType: "blob"
        }
      ).then((response) => {
        const extensao = tipo === "excel" ? "xlsx" : "pdf";
        const content = response.headers["content-type"];
        download(
          response.data,
          `Wellon - Relatório Detalhado.${extensao}`,
          content
        );
      });
    },

    handleScroll() {
      this.showScrollTop = window.scrollY > 300;
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  computed: {
    exibirDatasPeriodo() {
      return this.frmFiltrarRelatorio.periodo == "personalizado";
    }
  }
};
</script>
