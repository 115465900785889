<template>
  <div>
    <b-button
      ref="mdlEmailB"
      variant="light-primary"
      size="sm"
      class="btn-icon"
      v-b-tooltip.noninteractive
      title="Visualizar email"
      @click.prevent="abrir"
    >
      <i class="fas fa-envelope-open"></i>
    </b-button>

    <b-modal
      ref="mdlEmail"
      size="lg"
      scrollable
      ok-only
      ok-title="Fechar"
      ok-variant="secondary"
    >
      <template
        #modal-header="{ close }"
        class="d-flex justify-content-between"
      >
        <h5>Email enviado</h5>
        <div>
          <button
            type="button"
            class="close mt-6"
            aria-label="Close"
            @click="close()"
          >
            ×
          </button>
        </div>
      </template>

      <template>
        <div v-html="email" @click.prevent="bloquearClique"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    email: String
  },

  data() {
    return {
      historicoChat: [],
      textoMensagem: ""
    };
  },

  methods: {
    abrir() {
      this.historicoChat = [];
      this.textoMensagem = "";
      const self = this;

      self.$refs.mdlEmail.show();
    },

    bloquearClique() {
      //Método vazio apenas para bloquear o clique no link do email
    }
  }
};
</script>
