<template>
  <div>
    <b-button
      ref="mdlHistoricoChata"
      variant="light-primary"
      size="sm"
      class="btn-icon"
      v-b-tooltip.noninteractive
      title="Histórico"
      @click.prevent="abrir"
    >
      <i class="fas fa-comments"></i>
    </b-button>

    <b-modal
      ref="mdlHistoricoChat"
      size="lg"
      scrollable
      ok-only
      ok-title="Fechar"
      ok-variant="secondary"
      @shown="rolarPagina"
      @hide="pararAtualizacao"
    >
      <template
        #modal-header="{ close }"
        class="d-flex justify-content-between"
      >
        <h5>{{ obterTituloHistorico }}</h5>
        <div>
          <button
            type="button"
            class="btn btn-icon btn-sm mr-6"
            @click="atualizacaoAutomatica"
          >
            <i
              class="fas fa-stopwatch"
              :class="{
                'text-muted': !intervaloAtualizar,
                'text-danger': !!intervaloAtualizar
              }"
            ></i>
          </button>
          <button
            type="button"
            class="close mt-6"
            aria-label="Close"
            @click="close()"
          >
            ×
          </button>
        </div>
      </template>
      <template #modal-footer v-if="!checkAdminGeral && !checkSuperAdmin">
        <b-input-group>
          <b-form-input
            name="textoMensagem"
            v-model="textoMensagem"
            placeholder="Digite a mensagem..."
            @keyup.enter="enviarMensagem"
            autofocus
          ></b-form-input>

          <b-input-group-append>
            <button
              type="button"
              class="btn btn-info font-weight-bold px-10"
              @click="enviarMensagem"
            >
              Enviar
            </button>
          </b-input-group-append>
        </b-input-group>
      </template>

      <template>
        <template v-for="msg in historicoChat.historico">
          <div :key="msg.id">
            <botao-mensagem :msg="msg"></botao-mensagem>
          </div>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import Inputmask from "inputmask";
import BotaoMensagem from "./BalaoMensagem";

export default {
  props: {
    msg: Object,
    conta: Object
  },

  components: {
    BotaoMensagem
  },

  data() {
    return {
      historicoChat: [],
      textoMensagem: "",
      intervaloAtualizar: null
    };
  },

  computed: {
    ...mapGetters(["checkAdminGeral", "checkSuperAdmin"]),

    obterTituloHistorico() {
      const contato = Inputmask.format(this.historicoChat.contato || "", {
        mask: ["99 (99) 9999-9999", "99 (99) 99999-9999"]
      });

      return "Histórico do contato: " + contato;
    }
  },

  methods: {
    abrir() {
      this.historicoChat = [];
      this.textoMensagem = "";
      const self = this;

      this.obterMensagens(true).then(function () {
        self.$refs.mdlHistoricoChat.show();
      });

      this.iniciarAtualizacao();
    },

    obterMensagens(exibirLoader = false) {
      return ApiService.post(
        `relatorio/detalhado/historico-chat/${this.msg.mensagem_id}/${this.msg.mensagem_recebida_id}`,
        null,
        null,
        exibirLoader
      ).then((response) => {
        this.historicoChat = response.data.data.historicoChat;
        const keys = Object.keys(this.historicoChat.historico);
        if (keys.length > 0) {
          keys.forEach((key) => {
            this.historicoChat.historico[key].configuracoes =
              this.configuracoesHistoricoChat(key);
          });
        }
      });
    },

    configuracoesHistoricoChat(chave) {
      const msg = this.historicoChat.historico[chave];
      const msgAnterior = this.historicoChat.historico[chave - 1];

      let configuracoes = {
        tipo: "align-items-end",
        cor: "bg-light-success",
        conteudo: msg.conteudo
          .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
          .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
          .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
          .replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<code class="whatsapp">$1</code>')
          .replace(/@template\((.*)\)/, ""),
        conteudoCompleto: msg.conteudo_completo
          .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
          .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
          .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
          .replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<code class="whatsapp">$1</code>')
          .replace(/@template\((.*)\)/, ""),
        exibirCabecalho: true,
        exibirData: true
      };

      if (msg.tipo == "REC") {
        configuracoes.tipo = "align-items-start";
        configuracoes.cor = "bg-light-primary";

        if (msg.mensagem_id == 0) {
          configuracoes.cor = "bg-light-danger";
        }
      } else {
        if (msg.status == "ENV") {
          configuracoes.cor = "bg-light-success";
        } else if (msg.status == "PEN") {
          configuracoes.cor = "bg-light-warning";
        } else {
          configuracoes.cor = "bg-light-danger";
        }
      }

      if (msgAnterior == undefined || msg.tipo != msgAnterior.tipo) {
        configuracoes.exibirCabecalho = true;
      } else {
        configuracoes.exibirCabecalho = false;
      }

      if (msgAnterior == undefined) {
        configuracoes.exibirData = true;
      } else {
        const data = moment(msg.data);
        const dataAnterior = moment(msgAnterior.data);

        if (data.isAfter(dataAnterior, "day")) {
          configuracoes.exibirData = true;
        } else {
          configuracoes.exibirData = false;
        }
      }

      return configuracoes;
    },

    enviarMensagem() {
      const self = this;

      if (self.textoMensagem.trim()) {
        ApiService.post(
          `relatorio/detalhado/envio-mensagem/${this.historicoChat.contato}`,
          {
            mensagem: this.textoMensagem
          }
        ).then((response) => {
          self.textoMensagem = "";
          self.obterMensagens(true).then(function () {
            self.$nextTick(() => {
              self.rolarPagina();
            });
          });
        });
      }
    },

    rolarPagina() {
      const elemento = this.$refs.mdlHistoricoChat.$refs.body;
      elemento.scrollTo({
        top: elemento.scrollHeight * 2,
        left: 0,
        behavior: "smooth"
      });
    },

    pararAtualizacao() {
      clearInterval(this.intervaloAtualizar);
      this.intervaloAtualizar = null;
    },

    iniciarAtualizacao() {
      const self = this;

      this.intervaloAtualizar = setInterval(function () {
        self.obterMensagens().then(function () {
          self.$nextTick(() => {
            self.rolarPagina();
          });
        });
      }, 5000);
    },

    atualizacaoAutomatica() {
      if (!this.intervaloAtualizar) {
        this.iniciarAtualizacao();
      } else {
        this.pararAtualizacao();
      }
    }
  },

  beforeDestroy() {
    this.pararAtualizacao();
  }
};
</script>
