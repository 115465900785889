<template>
  <div>
    <div
      class="popup-scrolltop-container"
      :style="`bottom: ${stylePosition.bottom}; right: ${stylePosition.right}`"
      @click="scrollTop"
    >
      <i class="fa-solid fa-circle-up" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stylePosition: {
      type: Object,
      default: () => {
        return {
          bottom: "75px",
          right: "20px"
        };
      }
    }
  },
  methods: {
    scrollTop() {
      this.$emit("scrollTop");
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-scrolltop-container {
  position: fixed;
  right: 20px;
  width: 48px;
  height: 48px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  i {
    color: #185670;
    font-size: 2.5rem;
  }
}
</style>
