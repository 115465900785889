<template>
  <div>
    <div
      class="d-flex flex-column align-items-center m-5"
      v-if="msg.configuracoes.exibirData"
    >
      <span class="label label-light-info label-inline label-xl">{{
        msg.dataformatada
      }}</span>
    </div>

    <div class="d-flex flex-column" :class="msg.configuracoes.tipo">
      <div
        class="d-flex align-items-center"
        v-if="msg.configuracoes.exibirCabecalho"
      >
        <div class="symbol symbol-circle symbol-40 mr-3">
          <img alt="Pic" src="/media/users/blank.png" />
        </div>
        <div>
          <span class="text-dark-75 font-weight-bold font-size-h6">{{
            msg.numero
          }}</span>
          <span class="text-muted font-size-sm d-block">{{ msg.login }}</span>
        </div>
      </div>
      <div
        class="mt-2 rounded text-dark font-size-lg text-left max-w-400px min-w-150px"
        :class="msg.configuracoes.cor"
      >
        <template v-if="exibirImagem">
          <img
            :src="msg.link"
            style="max-width: 100%"
            class="p-2 rounded-lg"
            @error="exibirImagem = false"
          />
        </template>
        <p
          class="mb-0 pb-0 pl-2 pr-2 pt-2"
          style="white-space: pre-wrap"
          v-html="
            exibirImagem
              ? msg.configuracoes.conteudo
              : msg.configuracoes.conteudoCompleto
          "
        ></p>
        <p
          class="mb-0 pb-2 pl-2 pr-2 pt-0 text-right font-size-xs text-dark-50"
        >
          {{ msg.hora }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["msg"],

  data() {
    return {
      exibirImagem: true
    };
  }
};
</script>
